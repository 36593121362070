// extracted by mini-css-extract-plugin
export const mainColumn = "main-module--mainColumn--2088H";
export const workContainer = "main-module--workContainer--3nvrZ";
export const staggerAnimate = "main-module--staggerAnimate--16YOm";
export const contact = "main-module--contact--2w_fV";
export const center = "main-module--center--2rhqr";
export const imageLink = "main-module--imageLink--3GsZ_";
export const picture = "main-module--picture--3n5rf";
export const resume = "main-module--resume--Knhs0";
export const twoColumns = "main-module--twoColumns--2qoxu";
export const column_1 = "main-module--column_1--xNfjE";
export const waymo = "main-module--waymo--1UYKK";
export const highlightword = "main-module--highlightword--_ND1T";
export const container169 = "main-module--container169--2f_ZH";
export const screenshot = "main-module--screenshot--2B_xA";
export const imgCaption = "main-module--imgCaption--39d3g";